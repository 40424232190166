import React from 'react';
import { Table } from 'react-bootstrap';
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';


function Success() {

  return (
    <div style={{ textAlign: 'center'}}>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                <ScreenHeader message={"Visitor Training Tracker"}/>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
              <td style={{maxWidth: 850}}>
                <br/>
                <br/>
                  <Display message={`Thank you for submitting your acknowledgment! The submission has been recorded. 
                    You have YYY more visits remaining before further training or certification is required. You may now close this browser page.`}/>
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <br/><br/>
        
      <br/>
      <br/>
        {/*message={`Submit`}*/}
    </div>
  );
}

export default Success;

