import React, { useState } from 'react';
import { Link } from "react-router-dom"; 
import { NavDropdown, Table } from 'react-bootstrap';
import { useActionState } from 'react';
import DateTime from './utils/DateTime';

import useSWR from 'swr';
import { localAxios } from './api';
// >> Inputs, Labels, Switches, Checkboxes
// Container => Components[props]

const AcknowledgeForm = (props) => {

    const { data, error, isLoading } = useSWR('/me', localAxios) 

    const styles = {
      backgroundColor: "hsl(0, 0%, 22%)",
      color: "gold",
      padding: "10px 20px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      width: "130px"
    }

    const [isMinor, setIsMinor] = useState(false);

    const handleToggleForm = () => {
      setIsMinor(!isMinor);
    };
  
    return (
      <div className="form-container">
        <Table>
          <tbody>
            <tr>
              <td align="right">
                <label>
                    This visitor is a minor (under 18):
                </label>
              </td>
              <td>
                <input 
                  type="checkbox"
                  onChange={handleToggleForm}
                  name="minorFlag"
                  //value={inputs.first_name || ""}
                  //onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            {
              isMinor &&
                <tr>
                  <td align="right">
                    <label>
                      Minor Visitor First Name:
                    </label>
                  </td>
                  <td>
                    <input 
                      type="text"
                      name="minor_first_name" 
                      //value={inputs.first_name || ""}
                      //onChange={handleChange}
                      width={"200px"} 
                    />
                  </td>
                </tr>
            }
            {
              isMinor &&
                <tr>
                  <td align="right">
                    <label>
                      Minor Visitor Last Name:
                    </label>
                  </td>
                  <td>
                    <input 
                      type="text"
                      name="minor_last_name" 
                      //value={inputs.first_name || ""}
                      //onChange={handleChange}
                      width={"200px"} 
                    />
                  </td>
                </tr>
            }
              <tr>
                <td align="right">
                  <label>
                    {isMinor && "Parent/Guardian "}First Name:
                  </label>
                </td>
                <td>
                    <input 
                      type="text"
                      name="first_name" 
                      //value={inputs.last_name || ""}
                      //onChange={handleChange}
                      defaultValue={data?.data.first_name}
                      width={"200px"} 
                    />
                </td>
              </tr>
              <tr>
                <td align="right">
                  <label>
                    {isMinor && "Parent/Guardian "}Last Name:
                  </label>
                </td>
                <td>
                    <input 
                      type="text"
                      name="last_name" 
                      //value={inputs.last_name || ""}
                      //onChange={handleChange}
                      defaultValue={data?.data.last_name}
                      width={"200px"} 
                    />
                </td>
              </tr>
              <tr>
                <td align="right">
                  <label>
                      Email:
                  </label>
                </td>
                <td>
                  <input 
                      type="text"
                      name="email" 
                      defaultValue={data?.data.email}
                      //onChange={handleChange}
                      width={"200px"} 
                    />
                </td>
              </tr>
              <tr>
                <td align="right">
                  <label>
                      Phone:
                  </label>
                </td>
                <td>
                  <input 
                      type="text"
                      name="phone" 
                      //value={inputs.phone || ""}
                      //onChange={handleChange}
                      defaultValue={data?.data.phone}
                      width={"200px"} 
                    />
                </td>
              </tr>
              <tr>
                <td align="right">
                  <label>
                      Organization:
                  </label>
                </td>
                <td>
                  <input 
                      type="text"
                      name="org" 
                      defaultValue={data?.data.organization}
                      //onChange={handleChange}
                      width={"200px"} 
                    />
                </td>
              </tr>
          </tbody>
        </Table>
      </div>
    );
  }

export default AcknowledgeForm;


/*
 const [inputs, setInputs] = useState({});
  
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }
  
    const handleSubmit = (event) => {
      event.preventDefault();
      alert(inputs);
    }

    function Submit() {
      const [state, formAction] = useActionState(submitForm, initialState);
      return
        (
          <button type="submit" disabled={pending}>
            { pending ? "Submitting..." : "Submit" }
          </button>
        );
    }

    function Form(action) {
      return (
        <form action={action}>
          <Submit />
        </form>
      );
    }
    */