import React from 'react';
import useSWR from 'swr';
import { localAxios } from './api';

const Display = (props) => {
    const { data, error, isLoading } = useSWR('/me', localAxios) 

    if (error) return <div>Failed to load page</div>
    if (isLoading) return <div>loading...</div>
    //props.message
    return (
        <div>
            Logged in as {data?.data.email || 'loading'}
            <br/>
            {props.message}
        </div>
    );
}

export default Display;