import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { 
  BrowserRouter as Router, 
  Navigate,
  useParams,
  useNavigate, 
  Routes,
  Route, 
  Link,
  useLoaderData,
 } from "react-router-dom"; 
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';
import CenteredTextContainer from '../CenteredTextContainer';
import Button from '../Button';
import AcknowledgeForm from '../AcknowledgeForm';
import DateTime from '../utils/DateTime';
import useSWR, { preload } from 'swr';
import { localAxios } from '../api';

export function loader() {
  // job of this function is to get the data we want in a component before it renders
  // the job is also to identify if we are logged in before accessing a protected route
  // this would typically be a fetch request inside here, but for now, return a string
  // I can't use const { data, error, isLoading } = useSWR('/me', localAxios) because that's a React Hook
  // Also, useEffect is a React hook I think.  This replaces those.

  
  return "Acknowledge default data goes here, including first name, last name, email, and phone.";
  // return getUserData();  // return the promise
}


/*
const navToSuccess = () => {
  const navigate = useNavigate();
  navigate('/success');
}
*/


function Acknowledge() {

  const { data, error, isLoading } = useSWR('/me', localAxios) 

  const [todayDate, setTodayDate] = useState(new Date());
  const navigate = useNavigate();
  const params = useParams();
  const acknowledgeData = useLoaderData();
  console.log(acknowledgeData);


  const postData = (event) => {

    event.preventDefault();  
  
    //var vttForm = new FormData(document.getElementById("form"));
  
    let ackForm = event.target.form;

    // Get both date and time
    setTodayDate(new Date());

    let formData = new FormData();
  
    //const jsonString = JSON.stringify(form);
  
    formData.append("first_name", ackForm.first_name.value);
    formData.append("last_name", ackForm.last_name.value);
    formData.append("email", ackForm.email.value);
    formData.append("phone", ackForm.phone.value);
    formData.append("organization", ackForm.org.value);
    formData.append("acknowledge_flag", "1");
    formData.append("recorded_datetime", todayDate.toISOString());
  
    //console.log("jsonString ", jsonString);
    console.log("formData is ", formData);
  
    // Now create a form object with key/value pairs
    let formDataObj = Object.fromEntries(formData.entries());
    console.log("formDataObj is ", formDataObj);
  
    let formJSON = JSON.stringify(formDataObj);
    console.log("formJSON is ", formJSON);
  
    return localAxios.post('/acknowledge', formJSON).then(response=>{
        console.log("processing after response")
        console.log(response)
        if(response) {
          navigate("/success");
        };
    }).catch((error) => {
        console.log(error)
    })
  

  
  }//postData


  return (
    <div style={{ textAlign: 'center'}}>
      
      <form class="form">
      {/*<form class="form" id="vttForm" method="post" action="https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/records">*/}
      {/*<form id="vttForm" method="post" action={"https://webhook.site/5fbb042c-2885-4d73-8e85-a87f25eccb05">*/}
      
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                <ScreenHeader message={"Visitor Training Tracker"}/>
                </td>
              <td /> 
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                  <h1>Site: {params.siteID}</h1>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                  <CenteredTextContainer/>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td colSpan={3}> 
                <div>
                  <AcknowledgeForm />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
              <td style={{maxWidth: 850}}>
                <br/>
                <br/>
                  <Display message={`I have read and do understand the above statement completely. I will follow the directions given.`}/>
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <br/>
        <label>
          I Agree: <input type="checkbox" name="acknowledge_flag" />
        </label>
        <br/><br/>
        
        <button onClick={ postData } >Submit</button>
        {/*<button type="submit" onSubmit={ navToSuccess }>Submit</button>*/}
      </form>
      <br/>
      <br/>
        {/*message={`Submit`}*/}
    </div>
  );
}

export default Acknowledge;


/*
<script>
function getCurDateTime() {
  var now = moment().format(); // Format the moment as a string
  var momentInput = document.getElementById("currentMoment");
  momentInput.value = now;
}
</script>

</head>

<body>

<div class="container">

<h1>Acknowledgment Form</h1>

<form action="api/records" method="POST" onsubmit="return getCurDateTime();">

<div class="form-group">

  <label for="email">Email</label>

  <input type="text" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter Email" name="email">

</div>

<div class="form-group">

  <label for="phone">Phone</label>

  <input type="text" class="form-control" id="phone" aria-describedby="emailHelp" placeholder="Enter Phone" name="phone">

</div>

<div class="form-group">

    <label for="first_name">First Name</label>

    <input type="text" class="form-control" id="first_name" aria-describedby="emailHelp" placeholder="Enter First Name" name="first_name">

</div>

<div class="form-group">

    <label for="last_name">Last Name</label>

    <input type="text" class="form-control" id="last_name" aria-describedby="emailHelp" placeholder="Enter Last Name" name="last_name">

</div>

<div class="form-group">

  <input type="hidden" class="form-control" id="ackowledge_flag" aria-describedby="emailHelp" value="1" name="ackowledge_flag">

</div>

<div class="form-group">

  <input type="hidden" class="form-control" id="currentMoment" aria-describedby="emailHelp" value="" name="recorded_datetime">

</div>

<button type="submit" class="btn btn-primary">Submit</button>
*/