import React, {useState, useEffect} from 'react';
import Cookies from "js-cookie";

// The useLoaderData hook is used to pull in our data from the loader
import { 
  useLoaderData
 } from "react-router-dom";

 // The job of Outlet is to render any children routes that match the URL.
 // Could traditionally use Outlet to send user to a login page if not logged in.
 
import { Outlet } from "react-router-dom"
//import { Navbar } from "../components/navbar"
//import { Footer } from "../components/footer"
// <Navbar/>
// <Footer/>
// { isLoggedIn ? <Acknowledge /> : <Navigate to='/Login' }

export function loader() {
  // job of this function is to get the data we want in a component
  // this would typically be a fetch request inside here, but for now, return a string
  
  return "the Home page data is here";
}




const HomePage = () => {

  const sessionCookie = Cookies.get('vtt_user_session_cookie');
  if (sessionCookie) {
    // user is authenticated
    console.log("Session ID:", sessionCookie);
  } else {
    // user is not authenticated
    console.log("No session cookie found");
  }

  /*
    const isLoggedIn = Cookies.get('isLoggedIn');

  return (
    <div>
      {isLoggedIn ? (
        <p>Welcome, you are logged in!</p>
      ) : (
        <p>Please log in.</p>
      )}
    </div>
  );

  // or conditional rendering:

    const username = Cookies.get('username');

    return (
      <div>
        {username ? (
          <p>Welcome, {username}!</p>
        ) : (
          <p>Please log in.</p>
        )}
      </div>
    );
  }

  */


  // this changes when the data gets fetched.
  // How is this different from a regular useEffect?
  // Since we're not data fetching inside of the code of the component, 
  // react router can delay the rendering of our HomePage component until the loader has finished its task
  // slight change in paradigm
  const data = useLoaderData();
  console.log(data);

  return (
        <>
          <h1>Home Page</h1>
          
          <Outlet />
        </>
      )
}

export default HomePage;
