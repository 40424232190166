import Axios from 'axios'
import {Cookies} from 'react-cookie'

const DEV_API_ENDPOINT = "http://localhost:3001/api";
const PROD_API_ENDPOINT = "https://www.visitortrainer.com/api";

export const localAxios = Axios.create({
    baseURL: PROD_API_ENDPOINT,

    headers: {
        'Accept': 'application/json', 
        'Content-type': 'application/json',
    },
    // Default is same-origin. If we don't include credentials, the cookie doesn't show up in browser (by design)
    withCredentials: true
  })


localAxios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const cookies = new Cookies()
    const existingCookie = cookies.get('token')
    console.log("in localAxios call; existingCookie is ", existingCookie);
    if (existingCookie) {
        return {...config, headers: {
            ...config.headers,
            'coolHeader': existingCookie
        }}
    }
    return config;
    }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

/*
  localAxios.interceptors.response.use(function (config) {
    // Do something before request is sent
    // Ensure server sends correct error codes
    // 403 e.g. bad username password
    // Parse out various specific error
    // { statusCode: 403, statusText/statusReason: 'wrong password'}
    // { statusCode: 403, statusText/statusReason: 'banned user'}
    // { statusCode: 401, statusText/statusReason: ''} => new BadRequestException("User not found")
    console.log('error status', config.status, 'error code', config.statusText)
    //alert(config.statusText) -- created a prompt
  });
  */


//const login = (email,password) => localAxios.post('/login')